import './App.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';


const pollingInterval = 5000;
const BASE_URL = 'https://raspberrypi.taildd288.ts.net:8443';

function App() {

  const [auto_watering_status, setAutoWateringStatus] = useState(null);
  const [is_wet, setIsWet] = useState(null);
  const [analog_value, setAnalogValue] = useState(null);
  const [last_watered_time, setLastWateredTime] = useState(null);
  const [watering, setWatering] = useState(false);

  const getLastWatered = () => {
    axios.get(`${BASE_URL}/last_watered`)
    .then(response => {
      setLastWateredTime(response.data.last_watered);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }

  const getCurrentStatus = () => {
    axios.get(`${BASE_URL}/current_status`)
    .then(response => {
      setIsWet(response.data.is_wet);
      setAnalogValue(response.data.analog_value);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const getAutoWateringStatus = () => {
    axios.get(`${BASE_URL}/auto_status`)
    .then(response => {
      setAutoWateringStatus(response.data.auto_status);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const waterOnce = () => {
    setWatering(true);
    axios.post(`${BASE_URL}/water_once`)
      .then(response => {
        console.log('Success: Watered plant once', response.data);
        setWatering(false);
      })
      .catch(error => {
        console.error('Error watering plant once:', error);
        // mark as error
      });
      
  }
  
  /**
   * Toggle auto-watering feature on or off.
   *
   * @param {string} status - Either "ON" or "OFF".
   *
   * @returns {undefined}
   */
  const toggleAutoWatering = (status) => {
    axios.post(`${BASE_URL}/auto/water/${status === 1 ? 'ON' : 'OFF'}`)
      .then(response => {
        console.log(`Success: Auto-watering turned ${status}`, response.data);
        setAutoWateringStatus(status);
      })
      .catch(error => {
        console.error(`Error turning ${status} auto-watering:`, error);
      });
  };

  /** Kick off timers and get initial values for the status */
  useEffect(() => {

    getLastWatered();
    getCurrentStatus();
    getAutoWateringStatus();

    const current_status_interval = setInterval(getCurrentStatus, pollingInterval);
    const auto_watering_status_interval = setInterval(getAutoWateringStatus, pollingInterval);
    const get_last_watered_interval = setInterval(getLastWatered, pollingInterval);

    return () => {
      clearInterval(current_status_interval);
      clearInterval(auto_watering_status_interval);
      clearInterval(get_last_watered_interval);
    };

  }, []);

  return (
    <div>
      {is_wet === null ? (
        <div className="App" style={{height: "100vh"}}>
          <p>Loading...</p>
        </div>
      ) : (
        <div className="App" style={{backgroundColor: is_wet ? '#7bca88' : '#ca7b8c', height: "100vh"}}>
          <header className={`${is_wet ? 'bg-success' : 'bg-danger'} text-white text-center py-4`} style={{ width: '100%' }}>
            <h1 style={{marginBottom: '0px'}}>Pierce Street Plants</h1>
          </header>
          <div className=" justify-content-center">
            <p>&nbsp;</p>
            <h2><b>Last Watered: </b>{last_watered_time}</h2>
            <h4><b>Analog value: </b>{analog_value}</h4>
            <p>&nbsp;</p>
            <div>
              {/* Water Once Button */}
              <div className="container text-center my-4">
                <div
                  style={{
                    display: 'flex', // Enable Flexbox
                    justifyContent: 'center', // Horizontally center
                    alignItems: 'center', // Vertically center
                  }}
                >
                  <button
                    onClick={waterOnce}
                    className="btn btn-primary btn-lg"
                    type="button"
                    style={{
                      width: '90%',
                      height: '75px',
                      display: 'flex', // Enable Flexbox
                      justifyContent: 'center', // Horizontally center content
                      alignItems: 'center', // Vertically center content
                    }}
                  >
                    {watering && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      style={{ width: '2rem', height: '2rem' }}
                      aria-hidden="true"
                    ></span>
                    )}
                    <span
                      style={{
                        fontSize: 'x-large',
                        marginLeft: '10px', // Add some space between spinner and text
                      }}
                    >
                      Water Once
                    </span>
                  </button>
                </div>
              </div>
              <p>&nbsp;</p>
              {/* Centering the switch div using Bootstrap classes */}
              <div className="container my-4">
                <div className="row justify-content-center">
                  <div className="col text-center d-flex flex-column align-items-center justify-content-center" style={{ width: '100%' }}>
                    <h2>Auto Watering</h2>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={!!auto_watering_status}
                        onChange={() => toggleAutoWatering(auto_watering_status === 1 ? 0 : 1)}
                        style={{ width: '150px', height: '75px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
